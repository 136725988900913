import $ from "jquery";

$(function () {
    'use strict'

    $("#message_success").hide();
    $("#message_failure").hide();

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.querySelectorAll('.needs-validation');

    $('form.needs-validation').on('submit', (function (event) {
        if (!forms[0].checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            //Contact
            var str = $(this).serialize();
            $.ajax({
                type: "POST",
                url: "contactform/contactform.php",
                data: str,
                success: function (msg) {
                    if (msg == 'OK') {
                        $("#message_success").show();
                        $("#form_contact").hide();
                    } else {
                        $("#message_failure").show();
                        $("#form_contact").hide();
                    }
                },
                error: function (jqXHR, exception) {
                    var msg = '';
                    if (jqXHR.status === 0) {
                        msg = 'Not connect.\n Verify Network.';
                    } else if (jqXHR.status == 404) {
                        msg = 'Requested page not found. [404]';
                    } else if (jqXHR.status == 500) {
                        msg = 'Internal Server Error [500].';
                    } else if (exception === 'parsererror') {
                        msg = 'Requested JSON parse failed.';
                    } else if (exception === 'timeout') {
                        msg = 'Time out error.';
                    } else if (exception === 'abort') {
                        msg = 'Ajax request aborted.';
                    } else {
                        msg = 'Uncaught Error.\n' + jqXHR.responseText;
                    }
                    console.log(msg);
                    
                    $("#message_failure").show();
                    $("#form_contact").hide();
                }
            });
            return false;
        }
        forms[0].classList.add('was-validated');
    }));
});