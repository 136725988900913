// 
// user.js
// Use this to write your custom JS
//

// Utilizzo questa funzione per aggiungere la classe active alla voce del menu corrispondente alla pagina visitata
function aggiungiClasseSeInPagina(pagina, idDiv) {
    if (window.location.href.indexOf(pagina) > -1) {
      const div = document.getElementById(idDiv);
      if (div) {
        div.classList.add('active');
      }
    }
  }
  
  // Utilizzo della funzione
  aggiungiClasseSeInPagina('index.html', 'navbarPagesHome');
  aggiungiClasseSeInPagina('about.html', 'navbarPagesAboutUs');
  aggiungiClasseSeInPagina('framework.html', 'navbarPagesSkills');
  aggiungiClasseSeInPagina('blog-showcase.html', 'navbarPagesShowCase');
  aggiungiClasseSeInPagina('portfolio-case-study.html', 'navbarPagesPortfolio');
  aggiungiClasseSeInPagina('contact.html', 'navbarPagesContactUs');